<template>
  <div class="app-navbar-actions">
    <VaButton
      preset="secondary"
      @click="submit"
      color="textPrimary"
      class="app-navbar-actions__item flex-shrink-0 mx-0"
      icon="logout"
    >
      Log out
    </VaButton>
  </div>
</template>

<script lang="ts" setup>
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useToast } from 'vuestic-ui'

const { push } = useRouter()
const { init } = useToast()

import ProfileDropdown from './dropdowns/ProfileDropdown.vue'
import NotificationDropdown from './dropdowns/NotificationDropdown.vue'

const submit = () => {
  axios.post(import.meta.env.VITE_API_BACKEND + '/logout', {}, { withCredentials: true }).finally(() => {
    init({ message: 'Logging out', color: 'success' })
    document.cookie = 'AtomSession=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    push({ name: 'login' })
  })
}

defineProps({
  isMobile: { type: Boolean, default: false },
})
</script>

<style lang="scss">
.app-navbar-actions {
  display: flex;
  align-items: center;

  &__item {
    padding: 0;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    svg {
      height: 20px;
    }

    &--profile {
      display: flex;
      justify-content: center;
    }

    @media screen and (max-width: 640px) {
      margin-left: 0;
      margin-right: 0;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}
</style>
