<template>
  <svg
    id="Layer_1"
    :height="height"
    fill="none"
    version="1.1"
    baseProfile="basic"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 650 250"
    xml:space="preserve"
  >
    <g>
      <g>
        <path :fill="colorsComputed.start" d="M25,69h28.6v28.6H25V69z M53.6,156.3v28.6H25v-28.6H53.6z" />
      </g>
      <g>
        <path :fill="colorsComputed.start" d="M94.5,53.6h-30V25h58.6v200H64.5v-28.6h30V53.6z" />
      </g>
      <g>
        <path
          :fill="colorsComputed.start"
          d="M133.9,153.6h17.3V96.4h-17.3V67.9h63.2v28.6h-17.3v57.1h17.3v28.6h-63.2V153.6z"
        />
      </g>
      <g>
        <path
          :fill="colorsComputed.start"
          d="M286.6,153.6v-14.2h-15.1c-2,0-3.8-0.3-5.4-0.9c-1.6-0.6-2.9-1.3-3.9-2.1c-1-0.8-1.8-2-2.5-3.5c-0.7-1.5-1.2-2.9-1.6-4.1
                        c-0.3-1.2-0.6-2.8-0.7-4.7c-0.2-2-0.2-3.5-0.2-4.6c0-1.1,0-2.6,0.1-4.7c0.1-2,0.1-3.3,0.1-4h57.9v71.3h-74.5
                        c-9.9,0-18.3-3.5-25.3-10.4c-7-7-10.4-15.4-10.4-25.3v-42.8c0-9.9,3.5-18.3,10.4-25.3c7-7,15.4-10.4,25.3-10.4h74.5v28.6h-63.9
                        c-4.9,0-9.1,1.7-12.6,5.2c-3.5,3.5-5.2,7.7-5.2,12.6c0,1.2,0,3.1-0.1,5.8c-0.1,2.7-0.1,4.8-0.2,6.5c-0.1,1.7,0,3.8,0.2,6.4
                        c0.2,2.6,0.5,4.8,0.9,6.4c0.4,1.7,1.1,3.5,2,5.5c0.9,2,1.9,3.5,3.2,4.7c1.3,1.1,2.9,2.1,4.9,2.9c2,0.8,4.3,1.1,6.9,1.1H286.6z"
        />
      </g>
      <g>
        <path
          :fill="colorsComputed.start"
          d="M326.1,153.6h17.3V96.4h-17.3V67.9h63.2v28.6h-17.3v57.1h17.3v28.6h-63.2V153.6z"
        />
      </g>
      <g>
        <path :fill="colorsComputed.start" d="M400.1,182.1V67.9h28.6v85.7h102.4v28.6H400.1z" />
      </g>
      <g>
        <path :fill="colorsComputed.start" d="M541.9,182.1V67.9h28.6v85.7H625v28.6H541.9z" />
      </g>
      <g>
        <path :fill="colorsComputed.start" d="M439.5,67.9v74.9h91.6V67.9H439.5z M468.1,114.2V96.4h34.5v17.7H468.1z" />
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useColors } from 'vuestic-ui'

const { getColor } = useColors()

const props = withDefaults(
  defineProps<{
    height?: string
    start?: string
    end?: string
  }>(),
  {
    height: '35px',
    start: 'text-primary',
    end: undefined,
  },
)

const colorsComputed = computed(() => {
  return {
    start: getColor(props.start),
    end: getColor(props.end || props.start),
  }
})
</script>

<style scoped>
.navimg {
  max-height: v-bind(height);
}
</style>
