export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'clients',
      displayName: 'Clients',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'servers',
      displayName: 'Servers',
      meta: {
        icon: 'folder_shared',
      },
    },
    {
      name: 'settings',
      displayName: 'Settings',
      meta: {
        icon: 'settings',
      },
    },
    {
      name: 'firewall',
      displayName: 'Firewall',
      meta: {
        icon: 'local_fire_department',
      },
    },
  ] as INavigationRoute[],
}
