<template>
  <RouterView />
</template>

<script setup lang="ts">
import { useColors } from 'vuestic-ui'
const { applyPreset } = useColors()

const currTheme = localStorage.getItem('theme')
if (currTheme) {
  applyPreset(currTheme)
} else {
  // Change default to dark
  applyPreset('dark')
}
</script>

<style lang="scss">
@import 'scss/main.scss';

#app {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 20rem;
}
</style>
